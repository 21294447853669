import { createRouter, createWebHistory } from 'vue-router'

export const INITIAL_ROUTE_PATH = '/single-view'

const router = createRouter({
  history: createWebHistory(),
  routes: [],
})

export default router
