import { loggerInstance } from '@/services/logger'
import { ActivityFeed } from '@sennder/senn-node-microfrontend-interfaces'
import { microfrontends } from '@/config/microfrontends'
import { envOverrides, MicroFrontendLogger } from '@sennder/shell-utilities'
import { ENV } from '@/common/config'
import { getMfContext } from '@/router/routes'
import { AppAnalyticsProvider } from '@/services/analyticsProvider'
import { getStateData } from '@/store'
import { watch } from 'vue'

const getFml = async () => {
  const fml = microfrontends['activity-feed']
  const isHot = envOverrides.isHot(fml.npmName)
  // Fetch the ActivityFeed module
  const { default: remoteFns } = await fml.getModule<{
    default: ActivityFeed.IActivityFeedFunctions
  }>('./remotefunctions', isHot ? 'hot' : ENV)

  return remoteFns
}

let onSharedDataChanged:
  | ((data: ActivityFeed.IActivityFeedData) => void)
  | undefined

const analytics = new AppAnalyticsProvider({
  module: 'activity-feed',
  submodule: '',
})
const logger = new MicroFrontendLogger(
  {
    codeOwners: 'FES',
    module: 'activity-feed',
  },
  () => loggerInstance
)

const getActivityFeedData = () =>
  getMfContext(microfrontends['activity-feed'].npmName, logger, analytics)

watch(
  () => getStateData().language,
  async () => {
    const newData = await getActivityFeedData()
    onSharedDataChanged?.(newData.data)
  }
)

export const initActivityFeedProvider = async () => {
  // Function called when feed should be rendered
  const renderActivityFeed: ActivityFeed.RenderFunction = async (
    root,
    conf
  ) => {
    const fml = await getFml()

    const mfContext = await getMfContext(
      microfrontends['activity-feed'].npmName,
      logger,
      analytics
    )

    const mountFns = fml.mountActivityFeed(root, mfContext, conf)
    onSharedDataChanged = mountFns.onSharedDataChanged

    return mountFns
  }

  window.renderActivityFeed = renderActivityFeed
}
