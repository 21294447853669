<template>
  <dsf-navbar-item
    v-bind="$attrs"
    dsf_icon="CirclePlus"
    :dsf_title="$t('navigation.item.add-new-shipment')"
    dsf_id="create_shipment"
    @navigate.stop="onAddShipmentClick"
    :disabled="!loaded"
  />
  <component :is="mfWrapper"></component>
</template>

<script setup lang="ts">
import { onMounted, ref, shallowRef } from 'vue'

import {
  MicrofrontendEvents,
  showCreateShipmentFlow,
  publish,
} from '@sennder/senn-node-microfrontend-event-bus'
import { ISennTmsSharedData } from '@sennder/senn-node-microfrontend-interfaces'
import {
  createMfWrapper,
  MicroFrontendLogger,
  ComponentDefinition,
} from '@sennder/shell-utilities'

import { isLocalEnv } from '@/common/config'
import { wrapAuthWithLogging } from '@/modules/utils'
import { AppAnalyticsProvider } from '@/services/analyticsProvider'
import { logger } from '@/services/logger/loggers'
import { getStateCallbacks, getStateData, getStateProviders } from '@/store'
import { logout } from '@/store/logoutActions'
import router from '@/router'

import { loggerInstance } from '@/services/logger'
import { microfrontends } from '@/config/microfrontends'

const loaded = ref(false)
const mfWrapper = shallowRef<ComponentDefinition>()

const module = microfrontends.shipment_modal

const analyticsContext = {
  module: 'create-shipment',
  submodule: '',
}

const logContext = {
  codeOwners: 'ingestion',
  module: 'create-shipment',
}

const getMicrofrontendData = () => {
  const data = getStateData()
  if (!data.user) {
    throw new Error('state.data.user is not initialized')
  }
  return data as ISennTmsSharedData
}

const analyticsProvider = new AppAnalyticsProvider(analyticsContext)
const mfLogger = new MicroFrontendLogger(logContext, () => loggerInstance)

const { getAuthHeader, getAuthToken, getToken } = getStateCallbacks()

const onAddShipmentClick = () => {
  publish<showCreateShipmentFlow>({
    name: MicrofrontendEvents.showCreateShipmentFlow,
    data: {},
  })
}

onMounted(async () => {
  mfWrapper.value = await createMfWrapper<ISennTmsSharedData>({
    allowEnvironmentOverrides: isLocalEnv(),
    getData: async () => ({
      data: getMicrofrontendData(),
      callbacks: {
        ...getStateCallbacks(),
        getAuthHeader: wrapAuthWithLogging(getAuthHeader, mfLogger),
        getAuthToken: wrapAuthWithLogging(getAuthToken, mfLogger),
        getToken: wrapAuthWithLogging(getToken, mfLogger),
        onUnauthorized: async () => {
          await logout()
        },
        // modal widget will redirect to created shipment draft
        syncParentRouter: (route) => {
          router.push(route)
        },
      },
      providers: getStateProviders(),
    }),
    hooks: {
      failure: logger.error,
    },
    mf: {
      id: 'create-shipment-widget-new',
      fml: module,
      context: {
        analytics: analyticsContext,
        logger: logContext,
      },
    },
    providers: {
      getAnalytics: () => analyticsProvider,
      getLogger: () => mfLogger,
    },
    router,
  })

  loaded.value = true
})
</script>
