import { reactive, watch } from 'vue'

import {
  MicrofrontendEvents,
  onDispatchToastNotification,
  publish,
} from '@sennder/senn-node-microfrontend-event-bus'
import {
  GetTokenOptions,
  IAction,
  IMicrofrontendData,
  IOrg,
  ISennTmsSharedData,
  IUser,
  IUserRole,
  OrgType,
} from '@sennder/senn-node-microfrontend-interfaces'
import { getFeatureFlags } from '@sennder/senn-node-feature-flags-frontend'

import { getAuth0Header, getAuth0Token } from '@/services/tokenManager'
import { logger } from '@/services/logger/loggers'
import { logout } from '@/store/logoutActions'
import errorsHandler from '@/services/errors-handler'
import { analytics } from '@/services/analyticsProvider'
import { operationsService } from '@/services/operationsService'
import { i18n, defaultLanguage } from '@/services/i18n'
import {
  TENANT,
  SHARED_DATA_TYPE,
  ORCAS_URL,
  OPERATIONS_URL,
  SHOW_LANGUAGE_PICKER,
} from '@/common/config'
import { userManagementService } from '@/services/userManagementService'
import { Optional, Prettify } from '@/types/types'
import { sendErrorInMonitor } from '@/services/monitor'
import { auth } from '@/modules/auth'
import { notify } from './notify'
import router from '@/router'
import { canAccessCharteringPages } from '@/config/user-access'
import { routes } from '@/config/routes'
import { microfrontends } from '@/config/microfrontends'
import startAsyncService from '@/services/retry-service'
import { identifyUserInLaunchDarkly } from '@/services/launchDarkly'

// Constants for audiences
const CHARTERING_SINGLE_AUDIENCE = 'https://api.cloud.sennder.com'

const MFS_WITH_CHARTERING_AUDIENCE_NEEDED = [
  'marketplace',
  'chartering',
  'planner-mf-component',
  'orderDetails',
]

// NOTE: This function handles the audience selection based on the following criteria:
// 1. Some modules require a specific audience explicitly provided. If an audience is provided as an argument, it will be returned directly.
// 2. Certain modules inherently need to use the SENN_TMS audience, even if not explicitly specified. These modules are identified and the SENN_TMS_SINGLE_AUDIENCE is returned for them.
// 3. Chartering-related modules require the CHARTERING_SINGLE_AUDIENCE. If the module falls into this category, the function ensures that the chartering audience is selected.
// 4. The function acts as a filter to correctly assign the appropriate audience based on the module and context, ensuring all cases are handled consistently and accurately until all modules are adapted to use a single audience.
const getAudienceBasedOnModule = (): string | undefined => {
  const featureFlags = getStateData().featureFlags

  // Check if the feature flag for chartering MFs is enabled
  if (featureFlags['enable-chartering-mfs-senn-tms']) {
    const currentRoute = router.currentRoute.value
    const routeMeta = Object.values(routes).find(
      (route) => route.name === currentRoute.name
    )
    const module = routeMeta && microfrontends[routeMeta.mf]

    // Check if the current module requires a specific chartering audience
    const isCharteringSpecific =
      module && MFS_WITH_CHARTERING_AUDIENCE_NEEDED.includes(module.npmName)

    // Return the appropriate audience based on the module
    if (isCharteringSpecific) {
      return CHARTERING_SINGLE_AUDIENCE
    }
  }

  // Return undefined if no specific audience is determined
  return undefined
}

// Default options for getting an auth token
const defaultTokenOptions: GetTokenOptions = {
  usePopup: false,
  throwException: false,
}

const getAuthToken = async (options: GetTokenOptions = defaultTokenOptions) => {
  const { usePopup, throwException, ...restOptions } = options
  return getAuth0Token(restOptions, usePopup, throwException)
}

const getAuthHeader = async (
  options: GetTokenOptions = defaultTokenOptions
) => {
  const audience = options.audience || getAudienceBasedOnModule()
  const enrichedOptions = { ...options, audience }
  const { usePopup, throwException, ...restOptions } = enrichedOptions
  return getAuth0Header(restOptions, usePopup, throwException)
}

async function getCommonHeaders() {
  if (!auth.value) {
    throw new Error('[tms-shell - getCommonHeaders]: Auth module is not loaded')
  }
  //NOTE: Currently we are using the same audience for all the requests
  return await auth.value.getCommonHeaders(CHARTERING_SINGLE_AUDIENCE)
}

async function getPermissions(actions: IAction[]) {
  if (!auth.value) {
    throw new Error('[tms-shell - getPermissions]: Auth module is not loaded')
  }
  return await auth.value.getPermissions(actions, CHARTERING_SINGLE_AUDIENCE)
}

export async function getOrgTypeAndOrgIdOrFail() {
  if (!auth.value) {
    throw new Error(
      '[tms-shell - getOrgTypeAndOrgIdOrFail]: Auth module is not loaded'
    )
  }
  return await auth.value.getOrgTypeAndOrgIdOrFail()
}

export type SennTmsStoreData = Prettify<
  Optional<ISennTmsSharedData<object>, 'user'>
> & {
  org?: Org
}

type Org = {
  orgID: string
  orgType: OrgType
}

const getEmptyData = (): SennTmsStoreData => {
  return {
    language: defaultLanguage,
    tenant: TENANT,
    type: SHARED_DATA_TYPE,
    featureFlags: getFeatureFlags() || {},
    /**
     * TODO: some Octopus micro-frontends expect motership and orcas urls to be passed by the shell because of the multi-tenancy.
     * We can get rid of this and define all the URLs in the micro-frontends themselves.
     */
    env: {
      OPERATIONS_BACKEND_URL: OPERATIONS_URL as string,
      ORCAS_URL: ORCAS_URL as string,
    },
  }
}

export const store = reactive({
  state: {
    data: getEmptyData(),
    callbacks: {
      getToken: getAuthHeader,
      getAuthToken,
      getAuthHeader,
      getCommonHeaders,
      getOrgTypeAndOrgIdOrFail: getOrgTypeAndOrgIdOrFail,
      getPermissions,
      syncParentRouter: () => {},
      segmentTrackEvent: () => {},
      onUnauthorized: async () => {
        return logout()
      },
      onUnhandledError: errorsHandler,
      //TODO: We will need to update the interface to include the new callbacks
    } as any,
    providers: {
      monitoring: {
        sendError: sendErrorInMonitor,
      },
      logger,
      notifications: {
        error: (message: string) => notify(message, 'error'),
        success: (message: string) => notify(message, 'success'),
      },
      analytics,
    },
  } satisfies IMicrofrontendData<SennTmsStoreData>,
})

// watch feature flags changes in senn-node-feature-flags-frontend and update store
watch(
  getFeatureFlags,
  (featureFlags) => {
    store.state.data.featureFlags = featureFlags
  },
  { deep: true }
)

export const getStateData = (): SennTmsStoreData => {
  return store.state.data
}

// Loosely typed and dangerous, only use this function from dev-panel
export const DEV_setStateData = (data: object) => {
  // @ts-expect-error
  store.state.data = data
}

export const getStateUser = () => {
  if (!store.state.data.user) {
    throw new Error(
      '[tms-shell - getStateUser] state.data.user is not initialized'
    )
  }
  return store.state.data.user
}

export const getStateProviders = () => {
  return store.state.providers
}

export const getStateCallbacks = () => {
  return store.state.callbacks
}

export const getStateFeatureFlags = () => {
  return store.state.data.featureFlags
}

export const loadState = async () => {
  let user: IUser
  let org: Org | undefined
  try {
    ;[user, org] = await Promise.all([operationsService.getUser(), fetchOrg()])
    setStoreLanguage(SHOW_LANGUAGE_PICKER ? user.language : 'en')
  } catch (error) {
    logger.error(
      '[tms-shell - operationsService]: User data can not be loaded',
      {
        error,
      }
    )

    publish<onDispatchToastNotification>({
      name: MicrofrontendEvents.onDispatchToastNotification,
      data: {
        message: i18n.global.t('toast.error.message'),
        category: 'error',
        title: i18n.global.t('toast.error.title'),
      },
    })
    await logout()
    return false
  }

  await Promise.all([
    fetchUserRoles(user, org),
    startAsyncService(
      'LaunchDarkly',
      () => identifyUserInLaunchDarkly(user),
      {},
      0
    ),
  ])

  store.state.data = {
    ...store.state.data,
    user,
    env: {
      ...store.state.data.env,
      /**
       * TODO: Some Octopus micro-frontends are consuming user IDs like this.
       * This is a dirty hack to make them work in the new shell, AK to work with the teams to get rid of it.
       */
      USER_UUID: user.uuid as string,
      userUuid: user.uuid as string,
    },
    org,
  }
  return true
}

const fetchUserRoles = async (user?: IUser, org?: IOrg) => {
  if (!user?.uuid) {
    return
  }
  try {
    const uMRoles = await userManagementService.getUserRoles(user.uuid)
    const userRoles = [...uMRoles]

    if (canAccessCharteringPages(org)) {
      if (!auth.value) {
        logger.error(
          '[tms-shell - set user chartering roles]: Auth module is not loaded',
          {}
        )
      } else {
        const charteringRole = await auth.value.getCharteringUserRole?.(
          user.uuid
        )

        if (charteringRole) {
          const baseRole: IUserRole = {
            id: charteringRole.userRoleInfo?.uuid,
            name: charteringRole.userRoleInfo?.role,
            description: charteringRole.userRoleInfo?.name,
          }

          userRoles.push(baseRole)

          if (charteringRole.charteringOfficeName) {
            userRoles.push({
              ...baseRole,
              name: charteringRole.charteringOfficeName,
            })
          }
        }
      }
    }
    user.roles = userRoles
  } catch (error) {
    logger.error(
      '[tms-shell - userManagementService]: User roles can not be load',
      { error }
    )
  }
}

export const fetchOrg = async (): Promise<Org | undefined> => {
  try {
    const { orgId, orgType } = await getOrgTypeAndOrgIdOrFail()
    if (orgType && orgId) {
      return { orgID: orgId, orgType: orgType as OrgType }
    }
  } catch (error) {
    logger.error('[tms-shell - fetchOrg]: Error while getting org', {
      error,
    })
  }
}

export const setStoreLanguage = (language: string) => {
  store.state.data.language = language
}

export const setStoreFeatureFlags = (
  featureFlags: Record<string, boolean | string>
) => {
  store.state.data.featureFlags = featureFlags
}

export const setStoreData = (data: SennTmsStoreData) => {
  store.state.data = data
}

export const clearState = () => {
  store.state.data = getEmptyData()
}
